/*
 |-----------------------------------------------------------------------------
 | components/organisms/Search/Search.js
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { ReactNode, useEffect } from 'react';

import {
	Hits,
	InstantSearch,
	SearchBox,
	useInstantSearch,
} from 'react-instantsearch-hooks-web';
import algoliasearch from 'algoliasearch/lite';
import dynamic from 'next/dynamic';
import { get } from 'lodash';

import Text from '@/atoms/Text';

import { ISearchProps } from './types';

const Icon = dynamic(() => import('@/atoms/Icon'));
const Result = dynamic(() => import('@/molecules/Result'));

const searchClient = algoliasearch(
	process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
	process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY,
);

const EmptyQueryBoundary = ({ children, fallback }) => {
	const { indexUiState } = useInstantSearch();

	if (!indexUiState.query) {
		return fallback;
	}

	return children;
};

const Hit = ({ hit }) => {
	return (
		<Result
			text={get(hit, 'description')}
			title={get(hit, 'title')}
			url={get(hit, 'slug')}
		/>
	);
}

const NoResults = () => {
	const { indexUiState } = useInstantSearch();

	return (
		<Text utilities={`text-brand-1 text-sm`}>
			No results for{' '}
			<strong>{indexUiState.query}</strong>
		</Text>
	);
};

const NoResultsBoundary = ({ children, fallback }) => {
	const { results } = useInstantSearch();

	if (!results.__isArtificial && results.nbHits === 0) {
		return (
			<>
				{fallback}
				<div hidden>{children}</div>
			</>
		);
	}

	return children;
};

const TotalResults = () => {
	const { results } = useInstantSearch();

	return (
		<Text utilities={`text-brand-1 text-sm`}>
			Search returned{' '}
			<strong>{results.nbHits}</strong>
			{' '} results
		</Text>
	);
};

const Search = ({ index, placeholder }: ISearchProps) => {
	// console.log('Debug organism Search:', {
	// 	index: index,
	// 	placeholder: placeholder,
	// });

	useEffect(() => {
		const dialog = document?.querySelector('#search');
		const dialogInner = document.querySelector('#search div');

		const clickEventCallback1 = () => {
			dialog?.close();
		};

		const clickEventCallback2 = (e) => {
			e.stopPropagation();
		};

		const keyupEventCallback = (e) => {
			if (e.key === 'Escape') {
				dialog?.close();
			}
		};

		dialog?.addEventListener('click', clickEventCallback1);
		dialogInner?.addEventListener('click', clickEventCallback2);
		document.addEventListener('keyup', keyupEventCallback);

		return () => {
			dialog?.removeEventListener('click', clickEventCallback1);
			dialogInner?.removeEventListener('click', clickEventCallback2);
			document.removeEventListener('keyup', keyupEventCallback);
		};
	}, []);

	return (
		<dialog
			className={`
				backdrop:bg-brand-1/75
				bg-transparent
				max-h-[calc(768px/2)]
				max-w-[calc(768px-128px)]
				p-0
				w-full
			`}
			id="search"
			data-cy="search"
		>
			<div className={`bg-brand-3 p-4 space-y-4`}>
				<InstantSearch
					indexName={index}
					insights={process.env.NEXT_PUBLIC_ENV === 'production'}
					searchClient={searchClient}
				>
					<SearchBox
						classNames={{
							form: `relative`,
							input: `
								bg-transparent
								border-0
								border-b
								border-brand-1
								caret-brand-4
								font-normal
								placeholder:text-brand-1
								px-0
								text-xl
								text-brand-1
								w-full
								hover:border-b-brand-4
								focus:ring-0
								focus:border-b-brand-4
								focus:ring-transparent
							`,
							reset: 'absolute h-[38px] right-[38px] top-0 w-[38px]',
							submit: 'absolute h-[38px] right-0 top-0 w-[38px]',
						}}
						placeholder={placeholder}
						resetIconComponent={() => <Icon
							id="r-clear"
							utilities={`
								fill-brand-1
								h-8
								mx-auto
								w-8
							`}
						/>}
						submitIconComponent={() => <Icon
							id="r-search"
							utilities={`
								fill-brand-1
								h-8
								mx-auto
								w-8
							`}
						/>}
					/>
					<EmptyQueryBoundary fallback={null}>
						<NoResultsBoundary fallback={<NoResults />}>
							<TotalResults />

							<div
								className={`
								h-[200px]
								overflow-y-hidden
								hover:overflow-y-scroll
							`}
							>
								<Hits hitComponent={Hit} />
							</div>
						</NoResultsBoundary>
					</EmptyQueryBoundary>
				</InstantSearch>

				<Text utilities={`text-brand-1 text-right text-sm`}>
					Powered by {' '}
					<a
						className={`text-brand-1 underline`}
						href="https://algolia.com/"
						rel="external noopener noreferrer"
						target="_blank"
					>
						Algolia
					</a>
				</Text>
			</div>
		</dialog>
	);
};

export default Search;
