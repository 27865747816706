/*
 |-----------------------------------------------------------------------------
 | components/atoms/Text/Text.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC } from 'react';

// import { ray } from 'node-ray/web';

import * as IText from './types';

const Text: FC<IText.IProps> = ({
	children,
	schema,
	text,
	tinaField,
	utilities,
}) => {
	// ray('Debug atom Text:', {
	// 	schema: schema,
	// 	text: text,
	// 	tinaField: tinaField,
	// 	utilities: utilities,
	// }).red();

	return (
		<p
			className={utilities ? utilities : null}
			data-testid="text"
			data-tinafield={tinaField}
			itemProp={schema}
		>
			{children || text}
		</p>
	);
};

export default Text;
